
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.faq-list {
  position: relative;
}

.faq-list__category {
  & + & {
    padding: 4rem 0;
    border-top: 1px solid $c-black-10;
  }

  @include mq(m) {
    display: flex;
  }
}

.category__header {
  @include mq(m) {
    width: col(7, 20);
  }
}

.category__header__title {
  @extend %ff-alt;

  color: $c-blue;
}

.category__content {
  @include mq($until: m) {
    margin-top: $spacing * 1.5;
  }

  @include mq(m) {
    width: col(11, 20);
    margin-left: col(2, 20);
  }
}
